import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Images from './../utils/Images';
import { Slide, Fade, Box } from "@mui/material";

function BannerCarousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
  };

  const appendDots = (dots) => (
    <div style={{ position: "absolute", bottom: 10, left: "50%", transform: "translateX(-50%)" }}>
      <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
        {dots}
      </ul>
    </div>
  );

  return (
    <div className="bannerCarousel">
        <Slide direction="up" in={true} timeout={500} appear>
        <h1 className="">Unleash the power of diversity with Team24.</h1>
        </Slide>
        <Slide direction="up" in={true} timeout={600} appear>
        <Box>
          <Slider {...settings} appendDots={appendDots}>
            <div>
                <img src={Images.chai} alt="Chai" />
            </div>
            <div>
                <img src={Images.fitsupport} alt="Fit Support" />
            </div>
            <div>
                <img src={Images.gulabJamun} alt="Gulab Jamun" />
            </div>
            <div>
                <img src={Images.kokum} alt="Kokum" />
            </div>
            <div>
                <img src={Images.shampoo} alt="Shampoo" />
            </div>
            <div>
                <img src={Images.curcumin} alt="Curcumin" />
            </div>
            <div>
                <img src={Images.natureDay} alt="Nature Day" />
            </div>
          </Slider>
        </Box>
        </Slide>
    </div>
  );
}

export default BannerCarousel;