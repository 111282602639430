import React from 'react'
import { Box, Grid, Hidden, Slide } from '@mui/material';
import Images from '../utils/Images';
import Slider from "react-slick";

const Partners = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 1500,
    };

return (
    <div>
        <section data-aos="fade-up" className="distroPartners">
            <h1 className="text-center">Distribution Partners</h1>
            <Hidden smDown>
            <Grid container gap={2} sx={{ justifyContent: 'center', paddingBottom: '10px'}}>
                <Grid className="boxes" item sm={2} md={2} sx={{ display: 'flex', flexDirection: 'column'}}>
                    <img className='img-fluid'  src={Images.jioLogo} />
                </Grid>
                <Grid className="boxes" item sm={2} md={2} sx={{ display: 'flex', flexDirection: 'column'}}>
                    <img className='img-fluid'  src={Images.jiiLogo} />
                </Grid>
                <Grid className="boxes" item sm={2} md={2} sx={{ display: 'flex', flexDirection: 'column'}}>
                    <img  className='img-fluid' src={Images.starLogo} />
                </Grid>
                <Grid className="boxes" item sm={2} md={2} sx={{ display: 'flex', flexDirection: 'column'}}>
                    <img  className='img-fluid' src={Images.bigBazar} />
                </Grid>
            </Grid>
            </Hidden>
            <Hidden smUp>
            <div className="slider-container">
                <Slider {...settings}>
                    <div>
                        <Box className="boxes" sx={{ display: 'flex', flexDirection: 'column'}}>
                            <img className='img-fluid' style={{ alignSelf: 'center' }} src={Images.jioLogo} />
                        </Box>
                    </div>
                    <div>
                        <Box className="boxes" item sm={3} md={3} sx={{ display: 'flex', flexDirection: 'column'}}>
                            <img className='img-fluid' style={{ alignSelf: 'center' }} src={Images.jiiLogo} />
                        </Box>        
                    </div>
                    <div>
                        <Box className="boxes" item sm={3} md={3} sx={{ display: 'flex', flexDirection: 'column'}}>
                            <img className='img-fluid' style={{ alignSelf: 'center' }} src={Images.starLogo} />
                        </Box>        
                    </div>
                    <div>
                        <Box className="boxes" item sm={3} md={3} sx={{ display: 'flex', flexDirection: 'column'}}>
                            <img className='img-fluid' style={{ alignSelf: 'center' }} src={Images.bigBazar} />
                        </Box>        
                    </div>
                </Slider>
            </div>
            </Hidden>
        </section>
    </div>
  )
}

export default Partners
