import { Menu } from '@mui/icons-material';
import React, { useState, useRef } from 'react';
import Images from '../utils/Images';
import { Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';

const Navbar = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);

  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  const closeNav = () => {
    setIsNavCollapsed(false);
  };
  
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-brand">
          <div className="img-fluid navLogo" style={{ display: 'flex', alignItems: 'center' }}>
            <img className='img-fluid' src={Images.team24Head} alt="Logo" />
          </div>  
        </div>
        <div className='navbarBrandLinks'>
            <ScrollLink to="Home" smooth={true} duration={500}>
              <a className='navbarLinks-big'>Home</a>
            </ScrollLink>
            <ScrollLink to="Brands" smooth={true} duration={500}>
              <a className='navbarLinks-big'>Our Brands</a>
            </ScrollLink>
            <ScrollLink to="Partners" smooth={true} duration={500}>
              <a className='navbarLinks-big'>Our Partners</a>
            </ScrollLink>
            <ScrollLink to="Faqs" smooth={true} duration={500}>
              <a className='navbarLinks-big'>Faq's</a>
            </ScrollLink>
            <ScrollLink to="ContactUs" smooth={true} duration={500}>
              <a className='navbarLinks-big'>Contact Us</a>
            </ScrollLink>
        </div>
        <div className="menu-icon" onClick={handleNavCollapse}>
          <Menu />
        </div>
      </div>
      
      <Drawer sx={{ zIndex: 100011 }} anchor="right" open={isNavCollapsed} onClose={closeNav}>
        <List>
          <ScrollLink to="Home" smooth={true} duration={500}>
            <ListItem button onClick={closeNav}>
              <ListItemText primary="Home" />
            </ListItem>
          </ScrollLink>
          <ScrollLink to="Brands" smooth={true} duration={500}>
            <ListItem button onClick={closeNav}>
              <ListItemText primary="Our Brands" />
            </ListItem>
          </ScrollLink>
          <ScrollLink to="Partners" smooth={true} duration={500}>
            <ListItem button onClick={closeNav}>
              <ListItemText primary="Our Partners" />
            </ListItem>
          </ScrollLink>
          <ScrollLink to="Faqs" smooth={true} duration={500}>
            <ListItem button onClick={closeNav}>
              <ListItemText primary="Faq's" />
            </ListItem>
          </ScrollLink>
          <ScrollLink to="ContactUs" smooth={true} duration={500}>
            <ListItem button onClick={closeNav}>
              <ListItemText primary="Contact Us" />
            </ListItem>
          </ScrollLink>
        </List>
      </Drawer>
    </nav>
  );
};

export default Navbar;