import React from 'react';
import { Typography, Button, Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Faqs = () => {
  const [expanded, setExpanded] = React.useState(true);

  const handleChange = () => {
    setExpanded((prev) => !prev);
  };

    return(
        <section className="faqsContainer">
            <h4 class="text-center title">FAQ's</h4>
            <div item md={10} class="container pb-2">
                <div className="accordion">
                  <Accordion expanded={expanded} onChange={handleChange}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" className={expanded ? 'expandedAccordion' : ''} id="panel1a-header">
                      <Typography>What is private labeling and how does it work?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>Private labeling is a business model where we manufacture products under your brand name. You provide us with your specifications and packaging concept, and we conceptualize the product accordingly. This allows you to focus on marketing and selling the product, while we handle the formulation and manufacturing.</Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                      <Typography>What types of products do you manufacture? </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>We specialize in a wide range of products across various categories. Our product line includes (but is not limited to) food and beverages, nutraceuticals, cosmetics & household products. Please contact us for a detailed list of product categories we can manufacture.</Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                      <Typography>What is the minimum order quantity (MOQ) for your products?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>The MOQ varies depending on the type of product. For more specific information, please get in touch with us.</Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                      <Typography>Do you provide assistance with product design and packaging?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>Yes, we do! We have on-boarded a couple of design firms, which can help you with product design and packaging. We can work with you to create a design that aligns with your brand identity and appeals to your target market.</Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                      <Typography>What quality control measures do you have in place?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>We have stringent quality control measures in place to ensure the highest standards of final product formulations. Our process includes multiple stages of inspection & quality control from raw materials to the finished product. We also comply with all relevant industry standards and certifications. This ensures that the products we manufacture for your brand meets the quality expectations of your customers.</Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
            </div>
        </section>
    );
};

export default Faqs;