import React from 'react';
import { Button, Grid, Stack, Typography, Hidden } from '@mui/material';
import { Email, Instagram } from '@mui/icons-material';
import Images from '../utils/Images';

const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo({
        top: 0,
        behavior: 'smooth',
        });
    };

    return(
        <section data-aos="fade-up" className="footer">
            <Grid container justifyContent={'space-around'}>
                <Grid item xs={6} sm={3} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                    <img className="img-fluid" src={Images.team24Footer} />
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                    <Typography className='headers' variant='body1'>Mobile:</Typography>
                    <Typography className='subtitles' variant='body2'>+91-9028700000</Typography>
                    <Typography className='headers' variant='body1'>Landline:</Typography>
                    <Typography className='subtitles' variant='body2'>(0832) 2999826 / 2993826</Typography>
                    <Typography className='headers' variant='body1'>Email:</Typography>
                    <Typography className='subtitles' variant='body2'>info@team24.in</Typography>
                    <Typography className='headers' variant='body1'>Office Address:</Typography>
                    <Typography className='subtitles lastOne' variant='body2'>P12, Silvio Height, St Inez Rd,<br/> Panaji,<br/> Goa 403001.</Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3844.9150031085005!2d73.81663137464555!3d15.48900345471055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbfc11d2d0a4025%3A0xa4588d73e5eb733a!2sTeam24%20Foods%20and%20Beverages%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1708168766478!5m2!1sen!2sin" style={{ height: '100%', width: '100%', borderRadius: '20px' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </Grid>
                <Hidden smDown>
                    <Grid item md={1} sm={1} sx={{ display: 'flex', alignItems: 'center' }}>
                        <img onClick={scrollToTop} src={Images.upArrow} style={{ cursor: 'pointer' }} />
                    </Grid>
                </Hidden>
            </Grid>
        </section>
    );
};

export default Footer;