const Images = {

    //Video
    mainVideo: require('../assets/video/mainVideo.mp4'),

    kokum: require('../assets/images/ActiyoKokum.jpg'),
    shampoo: require('../assets/images/ActiyoShampoo.jpg'),
    curcumin: require('../assets/images/fissupportCurcumin.jpg'),
    fitsupport: require('../assets/images/fissupportAllProducts.jpg'),
    chai: require('../assets/images/NamasteChai.jpg'),
    gulabJamun: require('../assets/images/NamasteGulabJamun.jpg'),
    natureDay: require('../assets/images/Natureday.jpg'),
    navLogo: require('../assets/images/team24Logo.png'),

    team24Head: require('../assets/images/team24headLogo.png'),
    team24Small: require('../assets/images/team24small.png'),
    team24Big: require('../assets/images/team24Big.png'),
    team24Footer: require('../assets/images/team24Footer.png'),
    namasteLogo: require('../assets/images/namasteLogo.png'),
    goanRoom: require('../assets/images/goanRoom.png'),
    natureDayLogo: require('../assets/images/natureDayLogo.png'),
    fitsupportLogo: require('../assets/images/fitsupportLogo.png'),
    actiyoLogo: require('../assets/images/actiyoLogo.png'),

    jiiLogo: require('../assets/images/Jii.png'),
    jioLogo: require('../assets/images/Jio4.png'),
    starLogo: require('../assets/images/Star.png'),
    bigBazar: require('../assets/images/Big-Bazaar.png'),

    worldWide: require('../assets/images/worldWide.png'),
    inHouse: require('../assets/images/inHouse.png'),
    projects: require('../assets/images/projects.png'),
    map: require('../assets/images/map.png'),

    actiyoProduct: require('../assets/images/actiyoProduct.jpeg'),
    namasteProduct: require('../assets/images/namasteGulabJamun.jpeg'),
    upArrow: require('../assets/images/Uparrow.png'),
};

export default Images;