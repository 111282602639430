import React, { useEffect, useRef } from 'react';
import BannerCarousel from "./components/BannerCarousel";
import Brands from "./components/Brands";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import VideoPlayer from "./components/VideoPlayer";
import './sass/main.scss';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import Partners from './components/Partners';
import { Element } from 'react-scroll';
import Faqs from './components/Faqs';
import ContactForm from './components/ContactForm';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
    });
  }, []);

  return (
    <div className="App">
      <Navbar />
      <Element name="Home" className="element">
        <BannerCarousel />      
      </Element>
      <Element name="Brands" className="element">
        <Brands/>
      </Element>
      <Element name="Partners" className="element">
        <Partners />
      </Element>
      <div style={{ paddingBottom: '25px' }}>
        <VideoPlayer  />
      </div>
      <Element name="Faqs" className="element">
        <Faqs />
      </Element>
      <Element name="ContactUs" className="element">
        <ContactForm />
      </Element>
      <Footer />
    </div>
  );
}

export default App;