import React from 'react';
import ReactPlayer from 'react-player';
import { Grid, Box, Hidden } from '@mui/material';
import mainVideo from '../assets/video/mainVideo.mp4'

const VideoPlayer = () => {
    return(
        <section className="videoPlayer">
            <Grid container className="videoContainer" justifyContent={'center'}>
                <Grid data-aos="fade-left" item md={5}>
                    <Hidden mdDown>
                    <Box className="textBox">
                        <h1>Helping you<br/>
                        develop a <br/>
                        successful range<br/> 
                        of products</h1>
                        <h3>We at Team 24 have produced over<br/> 250 varieties of beverages, foods,<br/> neutralceuticals, cosmetics and health<br/> care products. Since our inception our<br/> goal is to help our associates create<br/> successful products by assisting them<br/> at every stage, right from<br/> understanding their idea, to production,<br/> trademarking and delivering the end<br/> product.</h3>
                    </Box>
                    </Hidden>
                    <Hidden mdUp>
                    <Box className="textBox">
                        <h1>Helping you
                        develop a
                        successful range 
                        of products</h1>
                        <h3>We at Team 24 have produced over 250 varieties of beverages, foods, neutralceuticals, cosmetics and health care products. Since our inception our goal is to help our associates create successful products by assisting them at every stage, right from understanding their idea, to production, trademarking and delivering the end product.</h3>
                    </Box>
                    </Hidden>
                </Grid>
                <Grid data-aos="fade-right" item md={5}>
                    <video src={mainVideo} autoPlay loop />
                </Grid>          
            </Grid>
        </section>
    );
};

export default VideoPlayer;