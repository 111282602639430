import { Box, Grid, Hidden, Slide } from '@mui/material';
import React from 'react';
import Images from '../utils/Images';
import Slider from "react-slick";

const Brands = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2500,
    };

    return(
        <>
        <section data-aos="fade-up" className="brandsContainer">
            <h1 className="text-center">Our Brands</h1>
            <Grid container className="brandsGrid"  sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                <a href='https://www.instagram.com/thegoanroom/?hl=en' target='_blank'>
                    <img className='img-fluid goanRoom' src={Images.goanRoom} />
                </a>
                <a href='https://natureday.in/' target='_blank'>
                    <img className='img-fluid natureDay' src={Images.natureDayLogo} />
                </a>
                <a href='https://actiyo.com/' target='_blank'>
                    <img className='img-fluid actiyo' src={Images.actiyoLogo} />
                </a>
                <a href='https://fitsupport.in/' target='_blank'>
                    <img className='img-fluid fitsupport' src={Images.fitsupportLogo} />
                </a>
                <a href='https://namastechai.in/' target='_blank'>
                    <img className='img-fluid namaste' src={Images.namasteLogo} />
                </a>
            </Grid>
        </section>
        <section data-aos="fade-up" className="afterBrands">
            <Grid container sx={{ justifyContent: 'center'}}>
                <Grid item md={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h1>At Team24, we believe in<br/> combining the strengths<br/> of different entities to<br/> make the extraordinary<br/> possible.</h1>
                </Grid>
                <Grid item md={4} sx={{ display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                    <img className="img-fluid" src={Images.team24Big} />
                </Grid>
            </Grid>
        </section>
        <section data-aos="fade-up" className="accomplishments">
            <Hidden smUp>
            <div className="slider-container">
                <Slider {...settings}>
                    <div>
                        <Box className="boxes" sx={{ display: 'flex', flexDirection: 'column'}}>
                            <img className='img-fluid' style={{ alignSelf: 'center' }} src={Images.inHouse} />
                            <h6>In-house Brands</h6>
                            <p>Over 20+</p>
                        </Box>
                    </div>
                    <div>
                        <Box className="boxes" item sm={3} md={3} sx={{ display: 'flex', flexDirection: 'column'}}>
                            <img className='img-fluid' style={{ alignSelf: 'center' }} src={Images.worldWide} />
                            <h6>Worldwide Presence</h6>
                            <p>In 5+ countries</p>
                        </Box>        
                    </div>
                    <div>
                        <Box className="boxes" item sm={3} md={3} sx={{ display: 'flex', flexDirection: 'column'}}>
                            <img className='img-fluid' style={{ alignSelf: 'center' }} src={Images.projects} />
                            <h6>Accomplished Projects</h6>
                            <p>200+</p>
                        </Box>        
                    </div>
                </Slider>
            </div>
            </Hidden>
            <Hidden smDown>
            <Grid container gap={1} sx={{ justifyContent: 'center'}}>
                <Grid className="boxes" item sm={3} md={3} sx={{ display: 'flex', flexDirection: 'column'}}>
                    <img className='img-fluid' style={{ alignSelf: 'center' }} src={Images.inHouse} />
                    <h6>In-house Brands</h6>
                    <p>Over 20+</p>
                </Grid>
                <Grid className="boxes" item sm={3} md={3} sx={{ display: 'flex', flexDirection: 'column'}}>
                    <img className='img-fluid' style={{ alignSelf: 'center' }} src={Images.worldWide} />
                    <h6>Worldwide Presence</h6>
                    <p>In 5+ countries</p>
                </Grid>
                <Grid className="boxes" item sm={3} md={3} sx={{ display: 'flex', flexDirection: 'column'}}>
                    <img className='img-fluid' style={{ alignSelf: 'center' }} src={Images.projects} />
                    <h6>Accomplished Projects</h6>
                    <p>200+</p>
                </Grid>
            </Grid>
            </Hidden>
        </section>
        </>
    );
};

export default Brands;