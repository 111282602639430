import React, { useState } from 'react';
import { TextField, Grid, Button, Box, Typography } from '@mui/material';
import axios from 'axios';

const ContactForm = () => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    mobile: '',
    message: '',
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newErrors = { ...errors };

    if (!formData.name.trim()) {
      newErrors.name = 'Full Name is required';
    } else {
      newErrors.name = '';
    }

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    } else {
      newErrors.email = '';
    }

    if (!formData.mobile.trim()) {
      newErrors.mobile = 'Mobile Number is required';
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      newErrors.mobile = 'Invalid mobile number (should be 10 digits)';
    } else {
      newErrors.mobile = '';
    }

    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    } else {
      newErrors.message = '';
    }

    setErrors(newErrors);

    const formIsValid = Object.values(newErrors).every((error) => !error);
    setIsFormValid(formIsValid);

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      axios
        .post('https://team24.in/api/contact.php', formData)
        .then((response) => {
          console.log('Response:', response.data);
          setFormData({
            name: '',
            email: '',
            mobile: '',
            message: '',
          });
          setIsFormSubmitted(true);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      // Handle form validation errors
    }
  };

  return (
    <Box className="contactForm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box>
        <form style={{ padding: '20px' }} onSubmit={handleSubmit}>
          <Typography className='title text-center'>Contact Us</Typography>
          <Grid container spacing={2} className='formBox'>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Full Name"
                variant="outlined"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email ID"
                variant="outlined"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                error={Boolean(errors.email)}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Mobile Number"
                variant="outlined"
                name="mobile"
                value={formData.mobile}
                onChange={handleInputChange}
                error={Boolean(errors.mobile)}
                helperText={errors.mobile}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Your Message"
                variant="outlined"
                multiline
                rows={3}
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                error={Boolean(errors.message)}
                helperText={errors.message}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" className='button' variant="contained" fullWidth>
                Contact Now
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default ContactForm;